import React, { useState } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import SearchBox from './components/SearchBox';
import AerodromePage from './components/AerodromePage';

const App = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <SearchBox
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/aerodrome/:icao"
          element={<AerodromePage />}
        />
      </Routes>
    </HashRouter>
  );
};

export default App;