import React from 'react';
import { Link } from 'react-router-dom';

const ResultItem = ({ result }) => (
  <Link to={`/aerodrome/${result.icao}`} className="block">
    <div className="bg-white rounded-lg shadow-md p-4 mb-4 cursor-pointer hover:shadow-lg transition-shadow duration-300">
      <h2 className="text-xl font-bold text-gray-800">
        {result.name}
        <span className="inline-flex items-center rounded-md ml-2 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">ICAO: {result.icao}</span>
        <span className="inline-flex items-center rounded-md ml-2 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">IATA: {result.iata}</span>
      </h2>
      <p className="text-gray-600">{result.city}</p>
    </div>
  </Link>
);

export default ResultItem;