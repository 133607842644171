import React, { useRef, useEffect } from 'react';
import { X } from 'lucide-react';

const MetarModal = ({ isOpen, onClose, metarData }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };

    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-10">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-2xl w-full relative max-h-[80vh] overflow-y-auto z-10">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Full Metar</h2>
        <div className="text-gray-700">
          <pre className="bg-gray-100 p-4 rounded overflow-x-auto text-sm">
            <code>
              {JSON.stringify(metarData, null, 2)}
            </code>
          </pre>
        </div>
      </div>
    </div>
  );
};

export default MetarModal;