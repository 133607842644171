import React, { useState, useEffect } from 'react';
import { Plane, CloudRain, Map } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import WeatherSection from './WeatherSection';
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import RunwayModal from './RunwayModal';


const AerodromePage = () => {
  const { icao } = useParams();
  const [aerodrome, setAerodrome] = useState(null);
  const [metar, setMetar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openModalIndex, setOpenModalIndex] = useState(null);

  useEffect(() => {
    const fetchAerodromeData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://avwx.rest/api/station/${icao}`, {
          headers: {
            'Authorization': 'Bearer y6hf1xOmeg-hxGFtM4vJxi6yHE4e9wOkZuV3X3_eVVc'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch aerodrome data');
        }
        const data = await response.json();
        setAerodrome(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchMetarData = async () => {
      try {
        const response = await fetch(`https://avwx.rest/api/metar/${icao}`, {
          headers: {
            'Authorization': 'Bearer y6hf1xOmeg-hxGFtM4vJxi6yHE4e9wOkZuV3X3_eVVc'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch metar data');
        }
        const data = await response.json();
        setMetar(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchAerodromeData();
    fetchMetarData();
  }, [icao]);

  if (loading) return <div className="min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 flex items-center justify-center">Loading...</div>;
  if (error) return <div className="min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 flex items-center justify-center">Error: {error}</div>;
  if (!aerodrome) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 p-8">
      <Link to="/" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">&larr; Back to Search</Link>
      <h1 className="text-4xl font-bold text-gray-800 mb-6">{aerodrome.name} ({aerodrome.icao})</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
            <Plane className="mr-2" /> Runway Information
          </h2>
          {aerodrome.runways.map((runway, index) => (
            <div key={index} className="mb-4">
              <RunwayModal
                isOpen={openModalIndex === index}
                onClose={() => setOpenModalIndex(null)}
                runway={runway}
              />
              <a
                className="underline text-blue-600 hover:text-blue-800 cursor-pointer"
                onClick={() => setOpenModalIndex(index)}
              >
                <h3 className="text-lg font-semibold">{runway.ident1}/{runway.ident2}</h3>
              </a>
            </div>
          ))}


        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
            <CloudRain className="mr-2" /> Weather Information
          </h2>
          <WeatherSection icao={aerodrome.icao} metarData={metar} />
          {/* <pre className="bg-gray-100 p-4 rounded overflow-x-auto text-sm">
            {metar.raw}
          </pre> */}
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
            <Map className="mr-2" /> Location
          </h2>
          <p>Elevation: {aerodrome.elevation_ft} ft</p>
          <p>Latitude: {aerodrome.latitude}</p>
          <p>Longitude: {aerodrome.longitude}</p>
          <p>Country: {aerodrome.country} {getUnicodeFlagIcon(aerodrome.country)}</p>
          <div className='-z-0'>
            <MapContainer className='h-40 -z-0' center={[aerodrome.latitude, aerodrome.longitude]} zoom={12} scrollWheelZoom={false}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AerodromePage;