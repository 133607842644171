import React, { useState } from 'react';
import { CloudRain } from 'lucide-react';
import MetarModal from './MetarModal';

const WeatherSection = ({ icao, metarData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!metarData) {
    return (
      <div className="">
        <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
          <CloudRain className="mr-2" /> Weather Information
        </h2>
        <p className="text-gray-600">No weather information available</p>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex items-center justify-between mb-4">
      </div>
      <pre className="bg-gray-100 p-4 rounded overflow-x-auto text-wrap text-sm">
        {metarData.raw}
      </pre>
      <MetarModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        metarData={metarData}
      />
      <div className="container py-5 px-10 mx-0 min-w-full flex flex-row items-center space-x-4">
        <button
          onClick={() => setIsModalOpen(true)}
          className="center bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
          See full metar
        </button>
        <button
          onClick={() => window.open(`https://metar-taf.com/${icao}`, '_blank', 'noopener noreferrer')}
          className="center bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
          METAR TAF
        </button>
      </div>

    </div>
  );
};


export default WeatherSection;