import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 60" className="w-full max-w-md mb-8">
    <defs>
      <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: '#4A00E0', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#8E2DE2', stopOpacity: 1 }} />
      </linearGradient>
      <filter id="shadow">
        <feDropShadow dx="0" dy="4" stdDeviation="3" floodOpacity="0.25" />
      </filter>
    </defs>
    <g filter="url(#shadow)">
      <text x="5" y="45" fontFamily="Arial, sans-serif" fontSize="16" fontWeight="bold" fill="url(#grad)">
        aerodrome<tspan fill="#4A5568">.lookup</tspan>
      </text>
      <path d="M3,55 Q70,40 145,55" stroke="url(#grad)" strokeWidth="2" fill="none" />
    </g>
  </svg>
);

export default Logo;