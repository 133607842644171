import React, { useState } from 'react';
import { Search } from 'lucide-react';
import Logo from './Logo'
import ResultItem from './ResultItem'

const SearchBox = ({ setSearchResults, searchTerm, setSearchTerm }) => {

  const [isFocused, setIsFocused] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsSearched(true);
    const results = await lookupStation(searchTerm);
    setSearchResults(results);
  };

  const lookupStation = async (q) => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const response = await fetch(`https://avwx.rest/api/search/station?text=${q}&airport=true&format=json`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer y6hf1xOmeg-hxGFtM4vJxi6yHE4e9wOkZuV3X3_eVVc'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setResults(result);
        console.log(JSON.stringify(result[0]));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={`min-h-screen bg-gradient-to-br from-purple-100 to-indigo-200 flex flex-col items-center transition-all duration-500 ease-in-out ${isSearched ? 'pt-8' : 'justify-center'}`}>
      <div className={`transition-all duration-500 ease-in-out ${isSearched ? 'scale-75' : ''}`}>
        <Logo />
      </div>
      <div className={`w-full max-w-md transition-all duration-500 ease-in-out ${isSearched ? 'scale-75' : ''}`}>
        <form onSubmit={handleSearch} className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            required
            placeholder="Search for aerodromes..."
            className={`w-full p-4 pr-12 text-gray-900 border border-gray-300 rounded-full shadow-lg focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300 ease-in-out ${isFocused ? 'scale-105' : ''
              } hover:shadow-xl`}
          />
          <button
            type="submit"
            className="absolute right-3 top-3 p-1 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 ease-in-out transform hover:scale-110 active:scale-95 shadow-md"
          >
            <Search size={24} className="transition-transform duration-300 ease-in-out transform hover:rotate-15" />
          </button>
        </form>
      </div>
      {isSearched && (
        <div className="w-full max-w-2xl mt-8 px-4">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            results.length === 0 ? (
              <div>No search results found</div>
            ) : (
              results.map((result) => (
                <ResultItem key={result.icao} result={result} />
              ))
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBox;
